<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">关键字：</span>
        <el-input class="width_150" v-model="search.keyword" placeholder="请输入关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类：</span>
        <el-select v-model="search.type">
          <el-option :value=-1 label="全部">全部</el-option>
          <el-option v-for="item of types" :key="item" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="keyword" label="关键字" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{getType(scope.row.type)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="排序权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" v-if="pager.page > 1" @query="getList()" />

    <!-- 添加修改 -->
    <el-dialog :title="dialogTitle" v-model="dialog" width="600px" top="20vh" :close-on-click-modal="false">
      <el-row>
        <el-col :span="4">关键字</el-col>
        <el-col :span="20">
          <el-input v-model="form.keyword" placeholder="关键字" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">分类</el-col>
        <el-col :span="20">
          <el-select v-model="form.type" :disabled="form.row != undefined">
            <el-option v-for="item of types" :key="item" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">权重</el-col>
        <el-col :span="20">
          <el-input v-model="form.weight" placeholder="权重" type="number" size="small"></el-input>
        </el-col>
      </el-row>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialog = false" >取消</el-button>
          <el-button type="primary" @click="add" v-if="dialogTitle == '添加'">保存</el-button>
          <el-button type="primary" @click="edit" v-else>保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "search",
  components: {
    page,
  },
  data() {
    return {
      tableData: [], //数据
      dialogTitle:"",
      dialog:false,
      types:[
        {value:0,label:"热门推荐"},
        {value:1,label:"长视频"},
        {value:2,label:"短视频"},
        {value:3,label:"漫画"},
        {value:4,label:"美图"},
        {value:5,label:"小说"},
      ],
      form: {
        row:null,
        id:0,
        weight:0,
        type:1,
        keyword:"",
      }, // 表单数据
      pager:{
        page:1,row:10,total:0,
      },
      search:{
        keyword:"",
        type:-1,
      },
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    getType(_type){
      for (const t of this.types){
        if (t.value == _type){
          return t.label
        }
      }
      return "未知分类:"+_type
    },
    getList(){
      httpClient("searchList").post({
        "pageid":this.pager.page,
        "row":this.pager.row,
        "keyword":this.search.keyword,
        "type":this.search.type
      }).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    showDialog(row){
      this.dialog = true
      if(row != undefined){
        this.dialogTitle = "编辑:"+row.keyword
        this.form.row = row
        this.form.keyword = row.keyword
        this.form.type = row.type
        this.form.weight = row.weight
      }else{
        this.dialogTitle = "添加"
        this.form.keyword = ""
        this.form.type = 1
        this.form.weight = 0
      }
    },
    add(){
      httpClient("searchAdd").post({
        "keyword":this.form.keyword,
        "type":this.form.type,
        "weight":this.form.weight,
      }).then((res)=>{
        if (res.code == 0){
          this.$message({message:"添加成功",type:"success"})
          this.getList()
          this.dialog = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    edit(){
      httpClient("searchEdit").post({
        "keyword":this.form.keyword,
        "id":this.form.row.id,
        "weight":this.form.weight,
      }).then((res)=>{
        if (res.code == 0){
          this.$message({message:"修改成功",type:"success"})
          this.form.row.keyword = this.form.keyword
          this.form.row.weight = this.form.weight
          this.dialog = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    del(row){
      this.$confirm(
          "确定要删除关键字【" + row.keyword + "】吗？",
          "提示",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      ).then(()=>{
        httpClient("searchDel").post({
          "id":row.id,
        }).then((res)=>{
          if (res.code == 0){
            this.$message({message:"删除成功",type:"success"})
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    }
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
